<template>
  <v-card class="rounded-lg overflow-hidden">
    <v-card-text class="px-0 pb-0">
      <v-row
        style="border-radius: 0">
        <v-col cols="5">
          <v-row>
            <v-col cols="12" class="blue py-6 px-12">
              <v-toolbar-title class="d-flex flex-column text-center title white--text pl-0 pb-10">
                <v-icon class="my-10" size="125">mdi-account</v-icon>
                {{ $vuetify.lang.t('$vuetify.global.sex_label.' + item.sex) }} {{ item.lastname }} {{ item.firstname }}
              </v-toolbar-title>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="7" class="grey lighten-3 elevation-4 py-8 px-8">
          <v-row>
            <v-col class="d-flex justify-end">
              <v-icon @click="onDismiss">mdi-close</v-icon>
            </v-col>
          </v-row>
          <v-row class="d-flex flex-column">
            <v-col>
              <div class="button text-uppercase pb-4">{{ $vuetify.lang.t('$vuetify.global.informations') }}</div>
              <v-divider></v-divider>
              <div class="information-container pt-4">
                <div class="information-item d-flex py-1">
                  <v-icon>mdi-email</v-icon>
                  <div class="pl-4">{{ item.email }}</div>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>

export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    onDismiss: {
      type: Function,
      required: true
    }
  },
  data: () => ({}),
  methods: {}
}
</script>
